@import url("https://fonts.googleapis.com/css2?family=Nontserrat,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: revert, sans-serif!important;
  /* background:  neutralgrey!important; */
 /*  background: #f6f7ff!important; */
 background: #f8f8f8!important;
/*  background: #e0e4eb!important;
 background: url('../img/layout/background.jpg')!important; */
 background-repeat: no-repeat!important;
 background-attachment: fixed!important;
}

/* .css-1j618ly , .css-892zhy  {
transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10!important
} */

.css-1ps3826,.css-17swqo3 {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px!important;
}

option {
  color: black;
}

/* .css-dvxtzn {
  display: none!important;
} */

.css-1oamxn5 {
  padding: var(--chakra-space-1)!important;
}

.css-tdtvma {
  max-width: 255px;
  width: 100%!important;
}

.css-1f1mtmi {
  position: relative;
  top: -6%;
}

.css-1kxonj9 {
  width: 100%;
  position: relative;
  top: -5%!important;
}

.css-jw2vrk {
  font-size: 24px!important;
}

.chakra-heading.css-1bn8tjs {
  font-size: 24px!important;
}

.css-1aqgb0d {
  -webkit-margin-end: auto;
  margin-inline-end: auto;
  color: #182034!important;
  font-weight: var(--chakra-fontWeights-normal);
}

h2 {
  font-size: 32px!important
}

.css-1jdscsl {
  margin-top: var(--chakra-space-2)!important;
  font-size: var(--chakra-fontSizes-md);
}

.css-18176s2 {
  color: #555!important;
}

.css-10vzb76 {
  position: absolute;
  padding-top: 0px!important;
  top: 0px!important;
}

.css-12rqbe4 {
  color: #182034!important
}

.css-1q7mro {
  object-fit: contain;
  border-radius: var(--chakra-radii-lg);
  border-radius: 50%!important;
  width: 80%!important;
  height: 80%!important;
}

@media screen and (min-width: 960px){

  .css-1pz5kui {
    top: -20px!important;
  }  

  .css-ruv6i7 {
    margin-top: 0px;
    position: relative;
    left: -5%!important;
  }

  .css-1q7mro {
    object-fit: contain;
    border-radius: var(--chakra-radii-lg);
    max-width: 320px!important;
  }

  .css-xk2l8y {
    padding: var(--chakra-space-0)!important;
    position: relative;
    left: -3%!important;
  }

  .css-dqukc5 {
    padding: var(--chakra-space-0)!important;
    position: relative;
    left: -3%!important;
  }

  img.chakra-image.css-kgnyax {
    position: relative;
    top: 40px!important;
  }

  .css-1q4w0ba {
    position: relative;
    top: -15%!important
  }
  
  .css-1ehbw3u {
    position: relative;
    top: -12%!important;
  }
  
  .css-892zhy {
    width: 260px!important;
  }

  .css-1cop8ig {
      width: 50vw;
      background-attachment: fixed;
      position: fixed;
  }

  .css-1kxonj9 {
    width: 100%;
    position: relative;
    top: 8%;
  }

  .css-89x3gd {
    width: 100vw;
  }

  .css-akw81x {
    grid-template-columns: 75% 45%!important;
  }

  .css-1kxonj9 {
    width: 100%;
    position: relative;
    top: 8%!important;
  }

  .css-1ehbw3u {
    position: relative;
    top: 10px!important;
  }

  .css-yipamz {
    padding-top: 30px!important;
  }

  .css-twhw4q {
    top: -30px!important;
  }

  .css-1cop8ig {
    width: 50vw;
    background-attachment: fixed!important;
    position: fixed!important;
  }

  .chakra-button.css-1xqua7x {
    position:relative;
    top: -12px!important
  }

  .chakra-text.css-1h15lvr {
    position:relative;
    top: 55px!important
  }
}

/* @media screen and (min-width: 768px) {
  .css-twhw4q {
    top: 0px!important
  }
}
 */

@media (max-width: 650px){
  .css-dqukc5 {
    padding: var(--chakra-space-1)!important;
}

  .css-wf87cf {
    position: relative;
    font-size: 0.8rem!important;
  }

  .css-1pz5kui {
    position: absolute!important;
    top: 0!important
  } 
  
  .css-1kqjabd {
    margin-top: 20px!important;
  }

  .css-19hvqga {
    height: initial;
    padding-top: 0px!important;
  }

  .css-xk2l8y {
    padding: var(--chakra-space-0)!important;
  }

  .chakra-heading.css-1bn8tjs {
    margin-bottom: 10px!important;
  }

  .css-1wknv5x {
    margin-bottom: 16vh!important;
  }

  .css-1wknv5x {
    margin-top: var(--chakra-space-8)!important;
    -webkit-flex-direction: column!important;
    -ms-flex-direction: column!important;
    flex-direction: column!important;
  }

  .css-1m35lov {
    position: fixed;
    min-height: 100%;
    display: none;
  }

  .css-1pz5kui {
    position: absolute;
  }

  .css-twhw4q {
    top: 16px!important;
    position: static!important;
  }

 /*  .css-jw2vrk {
    font-size: 0px!important;
  } */

  .css-1j9g4r3 {
    margin-top: var(--chakra-space-18);
  }

  .css-yipamz {
    flex-direction: column-reverse!important;
  }

  .css-twhw4q {
    position: absolute!important;
  }

  .css-twhw4q {
    font-size: 22px;
  }

  .css-jw2vrk {
    font-size: 18px!important;
    position: relative;
    top: 10px!important;
  }

  .css-lv73et {
    font-size: 80%!important;
  }
  
  .css-g9cw6v {
      position: relative;
      top: 12px!important;
      left: 18px!important;
  }

  .css-zslr2w {
    display: none!important;
  }

  .chakra-text.css-1h15lvr {
    position:relative;
    top: 0px!important
  }
}

@media (max-width: 500px){
  .css-1j9g4r3 {
    flex-direction: column!important;
  }

  .css-1j9g4r3 {
    margin-top: var(--chakra-space-14)!important;
  }
}

@media (max-width: 400px){
  .css-wf87cf {
    position: relative;
    left: -18px!important;
    font-size: 0.6rem!important;
  }
}

@media (min-width: 320px){
  .css-1klumeg {
    line-height: 2.5!important;
  }
} 

@media (max-width: 400px){
  .css-hht1n {
    width: 90%;
    position: relative;
    left: 0px!important;
  }
}

@media screen and (min-width: 960px) {
  .css-17z1gmt {
    text-align: start;
    min-width: 140px!important;
  } 

  .css-19gienx {
    right: -18%!important;
    top: -30px
  }

 /*  .css-zvfk7x {
    position: relative;
    top: 5vh;
  } */

  .css-ohj55w {
    -webkit-padding-start: 24px!important;
    padding-inline-start: 24px!important;
    -webkit-padding-end: 24px!important;
    padding-inline-end: 24px!important;
  } 

  .css-1w6p7k3 {
    max-width: 94%!important;
  }

  .css-8fyels {
    max-width: 76%!important;
  }

  .css-1pz5kui {
    right: 7%!important
  }

  .css-1cy75t7 {
    padding: 20px;
    box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px, rgb(0 0 0 / 30%) 0px 8px 16px -8px!important
  }

  /* .css-1vyg2o3 { 
    position: relative;
    left: 3.5%
  } */
}


/* @supports (-webkit-appearance:none) {
  .css-1vyg2o3 { 
    position: relative;
    left: 2.5%
  }
} */

/* _:lang(x)::-internal-media-controls-overlay-cast-button, .css-1vyg2o3 { 
  position: relative;
  left: 3.5%
} */

/* @supports (-ms-ime-align:auto) {
  .css-1vyg2o3 { 
    position: relative;
    left: 0%
  }
} */
.css-1upytuj {
  color: #192a36!important;
}

.css-twhw4q {
 right: 8%!important;

}

.css-tdtvma {
  width: 100%!important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px!important;
  min-height: 100vh!important;
  z-index: 999999999999!important;
}

.css-1cy75t7,.css-ohj55w,.css-1i95n6l,.css-yq4haj,.css-8q69fv,.css-18ulj3b,.css-1afkoro,.css-stxnh7,.css-u9uyfk {
  box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px, rgb(0 0 0 / 30%) 0px 8px 16px -8px!important
}

.css-19gienx {
  left: 3%;
  background-color: #092034;
}

.chakra-modal__overlay.css-14rxmsw{
  opacity:0!important
}

.css-7zspnv {
  color: #5448ac!important;
}

.css-8q69fv {
  padding: 6px;
}

.css-1nxmawm {
  max-width: 100%!important;
  text-align: start!important;
}

/* p.chakra-text{
  color: var(--chakra-colors-secondaryGray-900)!important
} */

.css-161y6vf,.css-1nxmawm,.css-129cdr9,.css-1wcvtfv {
  color: #4a5058!important;
}

.css-luuvyl {
  display: flex;
  position: relative;
  top: 60px;
}

.css-1kv7huj {
  font-weight: 500;
  color: #232c37!important;
}

.chakra-button.css-1bls24u {
  display: none
}

.css-1n8s0bj {
  height: auto!important;
  width: 80px;
}

.css-14ufb7u {
  text-align: left!important;
}

@media (max-width: 650px) {
  .css-tdtvma {
    min-height: 112vh!important;
  }

  .css-luuvyl {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 70px;
  }

  .css-3eifpi {
    text-align: left!important;
    font-size: 28px;
  }

  .chakra-button.css-1bls24u {
    display: block
  }
  
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */ 
  .css-1vyg2o3 {
    position: relative;
    left: -10%!important;
  }

}

/* @supports not (-webkit-touch-callout: none) {
  /* CSS for other than iOS devices 
  .css-1vyg2o3 {
    position: relative;
    left: -19%;
  }
} */

@media (min-width: 650px) and (max-width: 1600px) {
/*.css-1vyg2o3 {
    width: 65%!important;
    position: relative;
    left: 0%!important;
  } */
  .css-1r21lrd {
    width: 100%;
    max-width: fit-content!important;
    margin-top: 0px!important;
    justify-self:center
  }

  .css-ruv6i7 {
    margin-top: 0px;
    margin-left: 30px;
  }

  .css-1vyg2o3 {
    display: grid!important;
    grid-gap: var(--chakra-space-10);
    grid-template-columns: repeat(2, minmax(0, 1fr))!important;
    margin-top: var(--chakra-space-2);
    margin: 0 auto;
    width: 100%!important;
  }
}

@media (max-width: 500px) {
  .css-1pz5kui {
    position: relative;
    left: -3%!important;
}

.css-1vyg2o3 {
  display: grid;
  min-width: 100%!important;
}
}

@media (max-width: 1000px) and (min-width: 601px) {
  .container.continum {
    padding-top: 140px!important;
    position: relative;
    left: 20%!important
  }
  
}
@media (max-width: 768px) and (min-width: 601px) {
  .css-zvfk7x {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(3, minmax(0, 3fr))!important;
    margin:0 auto!important;
    margin-bottom: 20px;
    width: 96%;
  }

  .css-inswfa {
    padding-top: 40px!important;
  }

  .css-aftius {
    width: 60%!important;
    height: 100%;
    border-radius: 20px;
    margin: 0 auto!important;
  }
}

@media (max-width: 600px) {
  .css-aftius {
    width: 80%!important;
    height: 100%;
    border-radius: 20px;
    margin: 0 auto!important;
  }
  .css-1wv9e55 {
    padding-top: 140px!important
  }

  .css-inswfa {
    padding-top: 40px!important;
  }

  .css-zvfk7x {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(2, minmax(0, 2fr))!important;
    margin:0 auto;
    margin-bottom: 20px;
    width: 94%;
  }

  .css-19uw993, textarea, select {
    width: 100%!important;
    margin: 0 auto;
  }

  

}

@media (max-width: 300px) {
  .css-zvfk7x {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(1, minmax(0, 1fr))!important;
    margin:0 auto;
    margin-bottom: 20px;
    width: 96%;
    
  }
}

.css-19gienx {
  padding-top: -8px!important;
}

.modal-video-body, .modal-video-inner, .modal-video-movie-wrap {
    width: auto;
    height: max-content;
    border: 0px solid transparent!important;
}

.modal-video-body:hover, .modal-video-inner:hover, .modal-video-movie-wrap:hover {
    width: auto;
    height: max-content;
    border: 0px solid transparent!important;
}

.css-exwyyx {
  position: relative;
  top: 40%!important;
}

/* .css-sl61el {
  display: none!important;
} */
.css-sl61el {
  position: absolute;
  left: 50%;
  top: -27px!important;
}

.css-1ngw63v {
  display: none!important;
}

.css-ps5lfl {
  display: none!important;
}

.css-zo00mm {
  z-index: 0!important;
}
